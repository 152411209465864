import React, { useState, useEffect } from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import moment from "moment/moment";
import axios from "axios";
import {apiConfig , apiWs} from "../../services/apiConfig";
import * as BsIcons from "react-icons/bs";
import { MD5 } from "crypto-js";
import "./Pay.css";

function Pay(props) {
  const cadena = "33" + moment().format("HH:mm:ss");
  const hash = MD5(cadena).toString().slice(0, 10);
  const datos = props.data;

  const [anticipo, setAnticipo] = useState([]);
  const [valorAnticipo, setValorAnticipo] = useState("0");
  const [medio, setMedio] = useState("1");
  const [efectivo, setEfectivo] = useState("0");
  const [transaccion, setTransaccion] = useState(hash);
  const [boton, setBoton] = useState(false);
  const [view, setView] = useState(false);
  const [enviado, setEnviado] = useState(false)
  const [loading,setLoading]=useState(false);

  const handleMedio = (e) => {
    setMedio(e.target.value);
  };

  const saldoReal = Number(datos.precio) - Number(valorAnticipo);

  const saldo =
    Number(datos.precio) - (Number(efectivo) + Number(valorAnticipo));

    const phoneNumber = datos.telefono
    const message = `Se registro el pago por ${datos.tratamiento}, precio $${datos.precio},  Codigo: #${datos.codigo} pagaste $${efectivo}.`; // Mensaje que se enviará

    const whatsappLink = `${apiWs}?numero=${phoneNumber}&token=valentina&mensaje=${encodeURIComponent(
      message
    )}`;

  function enviar(){
    axios
        .get(whatsappLink)
        .then((res) => {
          let  data  = res.data;
         
          if (Number(data.success) === 1) {           
          setEnviado(true)
          } 
        })
        .catch(function (res) {});
  }

  function handleSubmit(e) {
    e.preventDefault();
    if(Number(saldo) >= 0){   
    document.getElementById("formulario").reset();
    const registro = {
      id_turnos: datos.id,
      total: datos.precio,
      monto: efectivo,
      saldo: Number(saldo),
      id_medio_pago: medio,
      fecha_pago: moment(datos.fecha).format("YYYY-MM-DD"),
      transaccion: hash,
      id_business_office: datos.id_business_office,
      transaccionMp: transaccion,
    };
    
    axios
      .post(apiConfig + `pay/add/add.php`, registro)
      .then((res) => {
        let data = res.data;
        
        if (Number(data.success) === 1) {
          setTransaccion(hash);
          enviar();  

          getData();
          if(Number(saldo)===0){
            props.handleChangeP(2)
          }
        }
      })
      .catch(function (res) {});
    }else{
      window.alert('verifica el importe')
    }
  }
  function getData() {
    setEfectivo(0)
    axios
      .get(apiConfig + `pay/list/pay.php?id=` + datos.id)
      .then((res) => {
        let data = res.data;
        if (Number(data.success) === 1) {
          setAnticipo(data.data);
          let valor = 0;
          let x = data.data;

          x.map((a) => {
            valor = valor + Number(a.monto);
          });
          setValorAnticipo(valor);
        }
        props.getTurnos();
      })
      .catch(function (res) {});
      
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    // Ejecutar la función después de 3 segundos
    const delay = 600;
    const timer = setTimeout(() => {
      // Llamar a tu función aquí
      setLoading(true);
    }, delay);

    // Limpiar el temporizador cuando el componente se desmonte
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
    {loading ? <>   
        <Container>

        
    
          

         
          <div
            className="mb-3"
            style={{
              boxShadow: '0px 4px 15px rgba(79,79,79,0.1)',
              fontSize: '0.8rem',
              padding: '15px',
              margin: '5px',
              borderRadius: '2px',}}
          >
             
            {Number(saldoReal) === 0 &&
                            <>
                            <div className="text-center pt-5">
                              <div>
                                <h3>{datos.tratamiento}</h3>
                                <small>Total pagado</small><br/>
                                <h1 className="display-3" style={{color: '#736CED'}}>
                                  <strong>
                                  {Number(valorAnticipo).toLocaleString("es-AR", {
                                    style: "currency",
                                    currency: "ARS",
                                    minimumFractionDigits: 2,
                                  })}
                                  </strong>
                                </h1>
                              </div>
                            </div>
                            <div className="text-center">
                            {!enviado ?
                              <h2>
                                <BsIcons.BsWhatsapp
                                  onClick={enviar}
                                  style={{ color: "#736CED" }}
                                />
                              </h2>
                              :
                              <h2>
                                <BsIcons.BsSendCheck                        
                                  style={{ color: "#00e676" }}
                                />
                              </h2>
                              }
                            </div>
                              </>

            }
            
            <div className="pt-2 mb-3" style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}>
            <div className="payTitulo">{datos.nombre}, {datos.apellido}</div>
            <div className="payTitulo"> {moment().format("DD-MM-YYYY")}</div>
          </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <div className="text-start">
                  Código
                  <br />
                  <strong>{datos.codigo}</strong>
                </div>
                <div>
                  {datos.tratamiento}
                  <br />
                  {moment(datos.fecha).format("DD-MM-YYYY")} {datos.hora}
                </div>
              </div>
              <div>
                {Number(datos.precio).toLocaleString("es-AR", {
                  style: "currency",
                  currency: "ARS",
                  minimumFractionDigits: 2,
                })}
              </div>
            </div>  
            
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
            </div>
            <br />
            {anticipo.length > 0 && (
              <>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col" className="text-start">Fecha de pagó</th>
                      <th scope="col" className="text-start">Medio</th>
                      <th scope="col" className="text-end">Importe</th>
                    </tr>
                  </thead>
                  <tbody >
                    {anticipo.map((data) => {
                      return (
                        <tr>
                          <td className="text-start">
                            <small>
                              {moment(data.fecha_pago).format("DD-MM-YYYY")}
                            </small>
                          </td>
                          <td className="text-start">
                            <small>
                              {Number(data.id_medio_pago) === 1
                                ? "Efectivo"
                                : "Mercado Pago"}
                            </small>
                          </td>
                          <td className="text-end">
                            <small>
                              {Number(data.monto).toLocaleString("es-AR", {
                                style: "currency",
                                currency: "ARS",
                                minimumFractionDigits: 2,
                              })}
                            </small>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {Number(saldoReal) !== 0 &&
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <small>
                      <strong>Saldo a pagar</strong>
                    </small>
                  </div>
                  <div>
                    <strong>
                      {Number(saldoReal).toLocaleString("es-AR", {
                        style: "currency",
                        currency: "ARS",
                        minimumFractionDigits: 2,
                      })}
                    </strong>
                  </div>
                </div>
            }
                <br/>
               

              </>
            )}
          </div>
          

    
          <br />
          {Number(saldoReal) !== 0 &&
          <form onSubmit={handleSubmit} id="formulario">
            <div>
             
           


                          <div className="payNumero">
                            <div>
                              <span>$</span>
                            </div>              
                            <div >
                              <input
                                style={{fontSize:'4rem',
                                        width:'300px'
                              }}
                                type="number"
                                className="crono-amount-field__input"
                                aria-label="Indicanos cuanto dinero quieres transferir"
                                placeholder="0"
                                aria-describedby="amount-field-prefix"
                                onChange={(e) => setEfectivo(e.target.value)}
                                
                              />

                            </div>
                          </div>
                
            
                  <div className="text-center px-5">
                    <span
                      aria-hidden="true"
                      className="input-amount__message input-amount__message--default" style={{color: Number(saldo) < 0 ? 'red' : 'grey' }}
                    >
                      {Number(saldo).toLocaleString("es-AR", {
                        style: "currency",
                        currency: "ARS",
                        minimumFractionDigits: 2,
                      })}{" "}
                      saldo
                    </span>
                  </div>
   
           
            </div>
            <br />
            <br />
            <div className="d-grid  mb-4">
              <button className="btn btn-primary" type="submit">
                Cobrar
              </button>
            </div>
          </form>
          }
        </Container>
    
      </> :  <div className="text-center" style={{ paddingTop: "20%" }}>
              <Spinner animation="border" variant="primary" />
            </div>}      
    </>
  );
}
export default Pay;
