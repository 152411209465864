import React from "react";


const FormularioPaciente = ({datos ,handleChange}) => {

 const dato = datos;

  return (
    <div className="container">
     
        <>
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="p1-tab"
                data-bs-toggle="tab"
                data-bs-target="#p1"
                type="button"
                role="tab"
                aria-controls="p1"
                aria-selected="true"
              >
                Datos
              </button>            
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="p8-tab"
                data-bs-toggle="tab"
                data-bs-target="#p8"
                type="button"
                role="tab"
                aria-controls="p8"
                aria-selected="false"
              >
                Cobertura médica
              </button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="p1"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              {/* Datos personales del registro */}
              <br/>
              <div className="text-start mb-2">
              <small>Fecha de nacimineto</small>
              </div>
              
              <input
                type="date"
                className="form-control mb-3"
                name="fechaNacimiento"
                value={datos && datos.fechaNacimiento}
                onChange={handleChange}
                placeholder="Fecha de Nacimiento"
                required
              />
              <input
                type="number"
                className="form-control mb-3"
                name="edad"
                value={datos && datos.edad}
                onChange={handleChange}
                placeholder="Edad"
                required
              />
            
              <input
                type="text"
                className="form-control mb-3"
                name="direccion"
                value={datos && datos.direccion}
                onChange={handleChange}
                placeholder="Dirección de domicilio"
                required
              />
              <select className="form-control mb-3" name="estadoCivil" value={datos && datos.estadoCivil}
                onChange={handleChange} required>
                <option selected>Estado civil</option>
                <option value="Casada" >Casada</option>
                <option  value="Soltera">Soltera</option>
              </select>
    
            </div>
          
            <div
              className="tab-pane fade"
              id="p8"
              role="tabpanel"
              aria-labelledby="contact-tab"
            >
              <br/>
              {/* Cobertura médica */}
              <input
                type="text"
                className="form-control mb-3"
                name="obraSocial"
                value={datos && datos.obraSocial}
                onChange={handleChange}
                placeholder="Obra Social"
                required
              />
              <input
                type="text"
                className="form-control mb-3"
                name="planCobertura"
                value={datos && datos.planCobertura}
                onChange={handleChange}
                placeholder="Plan de Cobertura"
                required
              />
              <input
                type="text"
                className="form-control mb-3"
                name="tipoCobertura"
                value={datos && datos.tipoCobertura}
                onChange={handleChange}
                placeholder="Tipo de Cobertura"
                required
              />
              <input
                type="text"
                className="form-control mb-3"
                name="numeroSocio"
                value={datos && datos.numeroSocio}
                onChange={handleChange}
                placeholder="Número de Socio"
                required
              />
            </div>
          </div>
        </>


    
    </div>
  );
};

export default FormularioPaciente;
