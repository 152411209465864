import React, { useState, useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import axios from "axios";
import {apiConfig} from "../../../services/apiConfig";
import * as BsIcons from "react-icons/bs";
import imagenSVG from '../../../components/profile/user-generico.png';
import { ListGroup } from 'react-bootstrap';
import CustomerUpdate from '../../customer/CustomerUpdate/CustomerUpdate';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import moment from "moment";

const HistorialCliente = (props) => {
	const [cliente,setCliente] = useState([]);
	const [historial,setHistorial] = useState('')
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	useEffect(() => {
		setCliente(props.cliente)
	}, [props.cliente]);
	

	useEffect(() => {
		axios
        .get(apiConfig + 'turnos/list/individual.php?id=' + Number(props.cliente.id_cliente))
        .then((getData) => {
          setHistorial(getData.data);
		  
        });
	}, [cliente.id_cliente]);

	const handleClick = () =>{
		props.buscarPrestador()
		props.filtro('')
		props.cleanCliente()
		props.handleViewMobil()
	  }
	const actualizaInfo=(e)=>{
		setCliente(e);
		setShow(false);
	}
	const colorMap = {
		1: "#ffba57", // "solicitado"
		2: "#39afd1", // "reservado"
		3: "#3b82f6", // "confirmado"
		4: "#fa5c7c", // "suspendido"
		5: "#9e9e9e", // "no asistió"
		6: "#0acf97", // "finalizado"
	  };

	return <Container>
			<Row className="pt-3">
				<Col sm={12} md={12}>
					<Card >
						<Card.Body>
							<div style={{
								fontSize: '1.5rem',
								display: 'flex',
								alignItems: 'center',
								gap: '20px'

								}}>
							<BsIcons.BsArrowLeftShort  style={{fontSize:'2rem'}} onClick={handleClick}/>
								Información de cliente
							</div>
						</Card.Body>						
					</Card><br/>
					<Card>
						<Row>
							<Col sm={12} md={6}>
								<div className="container text-center pt-5">
									<div className="profile-pic" >
									<img alt={cliente.nombre} src={(cliente.img === '') ? imagenSVG : apiConfig+'customer/image/'+cliente.avatar_url} />
									</div>
									<div className="pt-3">
										<strong>{cliente.seudonimo}</strong>
									</div>
									<Row>
										<Col>
											<div>
												<strong>Reservas</strong><br/>{historial ? historial.length : '0'}
											</div>
										</Col>
										<Col style={{borderLeft: 'solid 1px #ededed'}}>
											<div>
											<strong>Última</strong><br/>{historial.length > 0 ? moment(historial[0].fecha).format("DD-MM-YYYY") : '-'}
											</div>
										</Col>										
									</Row><br/>
								</div>
							</Col>
							<Col sm={12} md={6} className="text-start" style={{borderLeft: 'solid 1px #ededed'}}>
								<div className="text-center pt-3">
									<h3>Datos personales</h3>
								</div>
								<ul>
									<li><strong>Nombre</strong><br/>{cliente.seudonimo}</li>
									<li><strong>Teléfono</strong><br/>{cliente.telefono ? cliente.telefono : 'No registrado'}</li>
									<li><strong>Correo electrónico</strong><br/>{cliente.email ? cliente.email : 'No registrado'}</li>
								</ul>
							</Col>
						</Row> 
					</Card><br/>
					<Card>
						<Card.Body className="text-start">
							<div><h3>Historial</h3></div>
							<div className="profile-scrollable">
							<ListGroup>
								{ historial  ? historial.map((data,index) => (
								<ListGroup.Item key={index}>
									<Row>
										<Col sm={12} md={6}>
											<div>
											<strong> {data.tratamiento}</strong> 
											</div>
											<div>
											{moment(data.fecha).format("DD-MM-YYYY")}{' '}{data.hora}
											</div>
											<div>
											<strong>Estado de pago:</strong> {data.estado_pago === 'Pendiente' && <span style={{color:'red'}}>{data.estado_pago}</span>}
											{data.estado_pago === 'Pago parcial' && <span style={{color:'#ffba57'}}>{data.estado_pago}</span>}
											{data.estado_pago === 'Pagado' && <span style={{color:'#0acf97'}}>{data.estado_pago}</span>}
											</div>
										</Col>
										<Col className="text-start"  sm={12} md={6}>
											<div>
											<strong>Importe:</strong> {Number(data.precio) > 0 ? Number(data.precio).toLocaleString("es-AR", {
																				style: "currency",
																				currency: "ARS",
																				minimumFractionDigits: 2,
																			})
																			: "$ 0,00"}
											</div>											
											<div>
												<strong>Observaciónes:</strong><br/> {data.observaciones}
											</div>											
										</Col>
									</Row>

								</ListGroup.Item>
								
								))
								:
								<div>Sin registros</div>
							}
							</ListGroup>
							</div>
						</Card.Body>
					</Card>

				</Col>
				<Col sm={12} md={6}>
					
				</Col>
			</Row>
			<Modal show={show} size="lg" onHide={handleClose}>
				<Modal.Header closeButton>				
				</Modal.Header>
				<Modal.Body><CustomerUpdate item={cliente} actualizaInfo={actualizaInfo}/></Modal.Body>
			</Modal>			
		   </Container>
};

export default HistorialCliente;
