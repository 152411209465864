import { useState, useEffect } from "react";
import * as BsIcons from "react-icons/bs";
import { NavLink } from "react-router-dom";
import axios from "axios";
import {apiConfig} from "../../services/apiConfig";
import useAuth from "../../auth/useAuth";
import userEvent from "@testing-library/user-event";
import { identity } from "@fullcalendar/core/internal";

const Medios = () => {
  const auth = useAuth();
  const user = JSON.parse(localStorage.getItem('user'));
  const [passwordType, setPasswordType] = useState("password");
  const [pk,setPk] = useState('');
  const [at,setAt] = useState('');

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  function guarda() {
	if (pk.length > 10 && at.length > 10) {
		let info = {
			id_business_unit: user.id_business_unit,
			pk: pk,
			at: at
		};

		axios
			.post(`${apiConfig}pay/integracion.php`, info)
			.then((res) => {
				let { data } = res;
				
			})
			.catch((error) => {
				console.error(error);
			});
	}
}
useEffect(()=>{
		axios
			.get(`${apiConfig}pay/integracionkey.php?id=${user.id_business_unit}`)
			.then((res) => {
				let { data } = res;
				if(Number(data.success) === 1){
					setAt(data.data[0].at)
					setPk(data.data[0].pk)					
				}
			})
			.catch((error) => {
				console.error(error);
			});
	
},[user]);
  return (
    <div className="container">
      <div className="text-start">
        <NavLink exact to={"/config"}>
          <h1 className="display-1">
            <BsIcons.BsArrowLeftShort className="app-color" />
          </h1>
        </NavLink>
      </div>
      <div className="card shadow mb-3" style={{ background: "#736CED" }}>
        <div className="card-body">
          <div className="text-center text-white">Medios de Pago</div>
        </div>
      </div>
      <br />
      <div>
		<h3>Cronogram , integra el plugin de Mercado Pago</h3>
		<p><strong>Checkout Pro</strong><br/>
Procesa pagos en el ambiente de Mercado Pago, de manera simple y segura con un diseño listo para usar.</p>
Para obtener credenciales <a href="https://www.mercadopago.com.ar/developers/es" className="text-primary" target="_blank">Aquí</a>
		<div className="text-start mb-5 pt-5">
		 
		</div>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="container text-start">
                Public Key
                <br />
                <div className="input-group pt-3 mb-3">
                  <input type="text" value={pk && pk} className="form-control" onChange={(e)=>setPk(e.target.value)} />
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="container text-start">
                Access Token
                <br />
                <div className="input-group pt-3 mb-3">
                  <input type="text"  value={at && at} className="form-control"  onChange={(e)=>setAt(e.target.value)}/>
                </div>
              </div>
            </div>
          </div>		  
        </div>
		<br/>
			<button className="btn btn-primary col-6" onClick={()=>guarda()}>Guardar</button>
      </div>
    </div>
  );
};

export default Medios;
