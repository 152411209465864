import React from "react";

const Dashboard = () => {
  return (
    <div className="container">
      <div className="row pt-5">
	  <div className="col-md-3 col-sm-12">
          <div className="card text-center text-white bg-primary mb-3" style={{ width: "18rem", height:"12rem" }}>
		  <div class="card-header">GANANCIAS</div>
			<div className="card-body">
              <h5 className="card-title"></h5>
              <p className="card-text">
               
              </p>           
            </div>
          </div>
        </div>
		<div className="col-md-3 col-sm-12">
          <div className="card text-center text-white bg-warning mb-3" style={{ width: "18rem", height:"12rem" }}>
		  <div class="card-header">VENTAS</div>
			<div className="card-body">
              <h5 className="card-title"></h5>
              <p className="card-text">
               
              </p>           
            </div>
          </div>
        </div>
		<div className="col-md-3 col-sm-12">
          <div className="card text-center text-white bg-secondary mb-3" style={{ width: "18rem", height:"12rem" }}>
		  <div class="card-header">PACIENTES</div>
		    <div className="card-body">
              <h5 className="card-title"></h5>
              <p className="card-text">
               
              </p>           
            </div>
          </div>
        </div>
		<div className="col-md-3 col-sm-12">
          <div className="card text-center text-white bg-danger mb-3" style={{ width: "18rem", height:"12rem" }}>
		  <div class="card-header">GASTOS</div>
			<div className="card-body">
              <h5 className="card-title"></h5>
              <p className="card-text">
               
              </p>           
            </div>
          </div>
        </div>
       
      </div>
    </div>
  );
};

export default Dashboard;
