import "./styles/Register.css";
import React, { useState, useEffect } from "react";
import {apiConfig, apiWs} from "../../services/apiConfig";
import axios from "axios";
import { NavLink } from "react-router-dom";
import * as BsIcons from "react-icons/bs";
import Spinner from 'react-bootstrap/Spinner';
import Terminos from "../../pages/Terminos/Terminos";
import Modal from 'react-bootstrap/Modal';

function Register() {
  const [passwordType, setPasswordType] = useState("password");
  const [message, setMessage] = useState("");
  const [ver, setVer] = useState(true);
  const [habilitarBoton, setHabilitarBoton] = useState(false);
  const [mostarMsg, setMostarMsg] = useState(false);
  const [exito, setExito] = useState(true);
  const [nameUser, setNameUser] = useState("");
  const [lastnameUser, setLastname] = useState("");
  const [phoneUser, setPhone] = useState("");
  const [emailUser, setEmail] = useState("");
  const [passwordUser, setPassword] = useState("");
  const [codigo, setCodigo] = useState("");
  const [idUser, setIdUser] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const generateRandomString = () => {
    const characters = "0123456789";
    let result = "";
    const charactersLength = characters.length;
    for (let i = 0; i < 6; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  useEffect(() => {
    setCodigo(generateRandomString);
  }, []);

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const registerUser = async (event) => {
   if (
      nameUser.length > 3 &&
      lastnameUser.length > 4 &&
      phoneUser.length > 9 &&
      emailUser.length > 5 &&
      passwordUser.length > 5
    ){  
   setHabilitarBoton(true)
    event.preventDefault();
    const registro = {
      name: nameUser,
      lastname: lastnameUser,
      phone: phoneUser,
      email: emailUser,
      password: passwordUser,
    };
    await axios
      .post(apiConfig + `auth/register.php`, registro)
      .then((res) => {
        let { data } = res;
        if (data.success && data.message) {
          setIdUser(data.message);          
          setVer(false);
          enviarCodigo();
        }
        setMessage(data.message);
        setMostarMsg(true);
        setHabilitarBoton(false)
      })
      .catch(function (res) {});
   }else{
      window.alert('Verifica los datos ingresados')
   }
  };

  function enviarCodigo() {
    axios
      .get(
        `${apiWs}?numero=${phoneUser}&mensaje=Tu%20codigo%20de%20activacion%20es:%20${codigo}%20&token=valentina`
      )
      .then((res) => {
        let { data } = res;
        if (data.success && data.message) {
        }
      })
      .catch(function (res) {});
  }

  const [code, setCode] = useState("");

  function onActive(id) {
   if (Number(code) === Number(codigo)) {
      axios
        .get(apiConfig + `profile/delete/active.php?id=${id}`)
        .then(() => {
         setExito(false)
        });
    }
  }

  if (ver) {
    return (
      <div className="loginRegister container">
        <div className="pt-1">
          <div className="text-start">
            <NavLink exact to={"/login"}>
              <h1 className="display-1">
                <BsIcons.BsArrowLeftShort className="app-color" />
              </h1>
            </NavLink>
          </div>

          <div className="container">
            <div className="text-start">
              <div className="col-md-6 col-sm-12 pt-1">
                <form onSubmit={registerUser}>
                  <div className="col-12 mb-3">
                    <small>Nombre</small>
                    <input
                      name="name"
                      type="text"
                      className="form-control"
                      onChange={(e) => setNameUser(e.target.value)}
                      pattern="^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$"
                      maxLength={20}
                      required
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <small>Apellido</small>
                    <input
                      name="lastname"
                      type="text"
                      className="form-control"
                      onChange={(e) => setLastname(e.target.value)}
                      pattern="^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$"
                      maxLength={20}
                      required
                    />
                  </div>

                  <div className="col-12 mb-3">
                    <small>Telefono </small>
                    <input
                      name="phone"
                      type="text"
                      className="form-control"
                      onChange={(e) => setPhone(e.target.value)}
                      minLength={10}
                      maxLength={10}
                      pattern="[0-9]+$"
                      required
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <small>Correo electrónico</small>
                    <input
                      name="email"
                      type="email"
                      className="form-control"
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>

                  <small>Contraseña</small>
                  <div className="input-group mb-3">
                    <input
                      name="password"
                      type={passwordType}
                      className="form-control"
                      onChange={(e) => setPassword(e.target.value)}
                      minLength={6}
                      maxLength={6}
                      required
                    />
                    <span
                      className="input-group-text app-click"
                      onClick={togglePassword}
                    >
                      {passwordType === "password" ? (
                        <small>Mostrar</small>
                      ) : (
                        <small>Ocultar</small>
                      )}
                    </span>
                  </div>
                  <br />
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexCheckDefault"
                      required
                    />
                    <label className="form-check-label" for="flexCheckDefault" onClick={handleShow}>
                      Términos y condiciones
                    </label>
                  </div>
                  <Modal show={show} size="lg" onHide={handleClose}>
                     <Modal.Header closeButton>
                        <Modal.Title>Términos y condiciones</Modal.Title>
                     </Modal.Header>                
                     <Modal.Body>
                        <Terminos />
                     </Modal.Body>
                  </Modal>
                  <br />

                  {!habilitarBoton ? (
                    <div className="d-grid  mb-4">
                      <button
                        type="submit"
                        className="btn app-button text-white shadow"
                      >
                        Continuar
                      </button>
                    </div>
                  ) : (
                    <div className="d-grid  mb-4">
                      <button                        
                        className="btn app-button shadow"
                        disabled
                      >
                         <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                           />
                           <span className="visually-hidden">Loading...</span>
                      </button>
                    </div>
                  )}
                  <div className="d-grid  mb-4">
                    {mostarMsg && (
                      <div
                        className="alert alert-danger text-center"
                        role="alert"
                      >
                        {message}{" "}
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <>
        {exito ? (
          <div className="container pt-5">
          <div className="text-start">
            <NavLink exact to={"/login"}>
              <h1 className="display-1">
                <BsIcons.BsArrowLeftShort className="app-color" />
              </h1>
            </NavLink>
          </div>
           
            <div className="col-sm-12 col-md-6 mb-3 text-start">
               <h5>Verificación de datos, ingrese el codigo enviado a su whatsapp, para activar su cuenta.</h5><br/>
              <small>Codigo</small>
              <input
                name="codigo"
                type="text"
                className="form-control"
                onChange={(e) => setCode(e.target.value)}
                required
              /><br/>
            <div className="d-grid  mb-4">
              <button
                className="btn app-button shadow"
                onClick={() => onActive(idUser)}
              >
                Continuar
              </button>
            </div>
            </div>
          </div>
        ) : (
          <div className="container registrar">
          <div className="text-start">
            <NavLink exact to={"/login"}>
              <h1 className="display-1">
                <BsIcons.BsArrowLeftShort className="app-color" />
              </h1>
            </NavLink>
          </div>
            <div className="text-start pt-5">
              <h1>
                <strong>Te has registrado exitosamente</strong>
              </h1>
              <p>Habre tu mail para más información, en caso de no encontralo revisa la carpeta de spam.</p>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default Register;
