import axios from 'axios';
import React, {useRef,useState,  useEffect} from 'react';
import {apiConfig} from "../../services/apiConfig";
import { NavLink  } from 'react-router-dom';
import * as BsIcons from 'react-icons/bs';
import useAuth from "../../auth/useAuth";
import Cropper from "react-easy-crop";
import Slider from "@material-ui/core/Slider";
import { dataURLtoFile } from "./utils/dataURLtoFile";
import cam from "./utils/camara.png";
import generico from "./utils/user-generico.png";
import CryptoJS from "crypto-js";
import {
   Form,
   Row,
   Col,
   Button,
   ListGroup,
   FloatingLabel,
   Modal
 } from "react-bootstrap";


export default function Prestador() {



    const auth = useAuth();
    const user = JSON.parse(localStorage.getItem('user'));
    
    const [APIData, setAPIData] = useState([]);
    const [filtro,setFiltro] = useState('');
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false); 
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false); 
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false); 
    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false); 
    const [nombre, setNombre] = useState('');
    const [message, setMessage]=useState(null);
    const [apellido, setApellido] = useState('');
    const [telefono, setTelefono] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
	const [local, setLocal] = useState('');
	const [locales, setLocales] = useState([]);
    const [passwordType, setPasswordType] = useState("password");
    const inputRef1 = useRef('');   
    const triggerFileSelectPopup1 = () => inputRef1.current.click();
    const [image1, setImage1] = useState(null);
    const [file1,setFile1]= useState(null);
    const [preview1, setPreview1] = useState(null);
    const [croppedArea, setCroppedArea] = React.useState(null);
    const [crop, setCrop] = React.useState({ x: 0, y: 0 });
    const [zoom, setZoom] = React.useState(1);
    const [datoSeleccionado,setDatoSeleccionado] = useState({
            id: '',
            nombre: '',
            apellido: '',
            phone: '',
            email: '',
            id_business_office: ''
            
                              
    });


    const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
      setCroppedArea(croppedAreaPixels);
    };


   const onDownload = () => {
      generateDownload(image1, croppedArea);
   };


  
const createImage = (url) =>
   new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
      image.src = url;
   });

function getRadianAngle(degreeValue) {
   return (degreeValue * Math.PI) / 180;
}

const getCroppedImg = async (imageSrc, pixelCrop, rotation = 0)=> {
   const image = await createImage(imageSrc);
   const canvas = document.createElement("canvas");
   const ctx = canvas.getContext("2d");

   const maxSize = Math.max(image.width, image.height);
   const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

   // set each dimensions to double largest dimension to allow for a safe area for the
   // image to rotate in without being clipped by canvas context
   canvas.width = safeArea;
   canvas.height = safeArea;

   // translate canvas context to a central location on image to allow rotating around the center.
   ctx.translate(safeArea / 2, safeArea / 2);
   ctx.rotate(getRadianAngle(rotation));
   ctx.translate(-safeArea / 2, -safeArea / 2);

   // draw rotated image and store data.
   ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5
   );

   const data = ctx.getImageData(0, 0, safeArea, safeArea);

   // set canvas width to final desired crop size - this will clear existing context
   canvas.width = pixelCrop.width;
   canvas.height = pixelCrop.height;

   // paste generated rotate image with correct offsets for x,y crop values.
   ctx.putImageData(
      data,
      0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
      0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
   );

   // As Base64 string
   // return canvas.toDataURL("image/jpeg");
   return canvas;
}



   const generateDownload = async (imageSrc, crop) => {
   if (!crop || !imageSrc) {
      return;
      }
      const canvas = await getCroppedImg(imageSrc, crop);
      const canvasDataUrl = canvas.toDataURL("image/jpeg");
      const convertedUrlToFile = dataURLtoFile(
            canvasDataUrl,
            "cropped-image.jpeg"
         );
      setFile1(convertedUrlToFile);      
      const reader = new FileReader();
         reader.readAsDataURL(convertedUrlToFile);
         reader.addEventListener("load", () => {
            setPreview1(reader.result);
            setShow1(false);            
         });
            
   };


   useEffect(() => {

      
         axios.get(apiConfig+'prestadores/list/?id='+Number(user.id_business_unit))
         .then((getData) => {
             setAPIData(getData.data);                
         });
		 axios.get(apiConfig+'business/list/?id='+Number(user.id_business_unit))
		 .then((getData) => {
			 setLocales(getData.data.data);                
		 });
  

          
        },[user.id_business_unit]);

    function getData(){
        axios.get(apiConfig+'prestadores/list/?id='+Number(user.id_business_unit))
        .then((getData) => {
            setAPIData(getData.data);                
        });
    }


    function onErase (id){       
        axios.get(apiConfig+`prestadores/delete/delete.php?id=${id}`)
        .then(() => {
            getData();
        })
    }

    function onDelete(id){
        axios.get(apiConfig+`prestadores/delete/index.php?id=${id}`)
        .then(() => {
            getData();
        })
    }

    function onActive(id) {
        axios.get(apiConfig+`prestadores/delete/active.php?id=${id}`)
        .then(() => {
            getData();
        })
    }
    
    const togglePassword =()=>{
        if(passwordType==="password")
        {
         setPasswordType("text")
         return;
        }
        setPasswordType("password")
      };

   function registerUser(event) {
      event.preventDefault();
      const registro = {
            nombre: nombre,
            apellido: apellido,
            telefono: telefono,
            email: email,
            password: password,            
            id_business_office: local,
            id_business_unit: user.id_business_unit
         }
         
      axios.post(apiConfig+`prestadores/create/register.php`,registro)
         .then((res) => {
            let {data} = res;
            setShow(false);              
            if(Number(data.success) === 1){
                createImageNew(data.message);          
                
               
                event.target.reset();
                if(message){
                    setMessage(false);
                }
            }
            setMessage(data.message)
            
       
         })
        .catch(function (res) {
            
      });
      getData();

    }

    

    const createImageNew = (variable) => {        
        const formData1 = new FormData();
              formData1.append('file',file1);
              formData1.append('id',variable);        
        axios.post(apiConfig+`prestadores/create/image.php`, formData1)
        .then((res) => {
              let {data} = res;                           
              if(data.success && data.message){              
                  if(data.success === 1){
                      setPreview1(null);
                      setImage1(null); 
                      setFile1(null);                
                  }
              }
            })
            .catch(function (res) {
              console.log(res)
            });
            getData();
    };



    const handleShowNew = () => {
      if(message){
        setMessage(null);
      }
      clearImg();      
      setShow(true)
    };

    const clearImg = () => {
        setImage1(null);
        setPreview1(null);
    };

    //editar perfil 


        const handleChange=e=>{
        const {name, value}=e.target;
            setDatoSeleccionado((prevState)=>({
              ...prevState,
              [name]: value
            }));
      }


      const onDownload1 = () => {
          generateDownload1(image1, croppedArea);
       };

       const generateDownload1 = async (imageSrc, crop) => {
       if (!crop || !imageSrc) {
          return;
          }
          const canvas = await getCroppedImg(imageSrc, crop);
          const canvasDataUrl = canvas.toDataURL("image/jpeg");
          const convertedUrlToFile = dataURLtoFile(
                canvasDataUrl,
                "cropped-image.jpeg"
             );
          setFile1(convertedUrlToFile);      
          const reader = new FileReader();
             reader.readAsDataURL(convertedUrlToFile);
             reader.addEventListener("load", () => {
                setPreview1(reader.result);
                setShow3(false);            
             });
                
       };

        function updateImage(variable){        
        const formData1 = new FormData();
              formData1.append('file',file1);
              formData1.append('id',variable);        
        axios.post(apiConfig+`prestadores/update/image.php`, formData1)
        .then((res) => {
              let {data} = res;                           
              if(data.success && data.message){              
                  if(data.success === 1){
                      setPreview1(null);
                      setImage1(null); 
                      setFile1(null)                
                  }

              }
            })
            .catch(function (res) {
              console.log(res)
            });
            getData();
        }

        

   function updateUser(event){ 
           
              event.preventDefault();
              axios.post(apiConfig+`prestadores/update/update.php`,datoSeleccionado)
                 .then((res) => {
                    let {data} = res;                  
                    if(data.success && data.message){                        
                        updateImage(datoSeleccionado.id);                                          
                  
                        setDatoSeleccionado({
                            id: '',
                            nombre: '',
                            apellido: '',
                            telefono: '',
                            email: '',
                            id_business_office:''                                                   
                        })
                       
                    }
                    handleClose2();
                        getData()
               
                 })
                .catch(function (res) {
                    
              });
              event.target.reset();
              
        }





     function setData(elemento){

        setDatoSeleccionado(elemento);
       
        setImage1(elemento.avatar_url);
        setPreview1(apiConfig+'prestadores/image/'+elemento.avatar_url);     
        setShow2(true)
    }



    return (
    	<>
<div className="container mx-auto ">
<div className="text-start">
			<NavLink exact to={'/config/'} >
				<h1 className='display-1'><BsIcons.BsArrowLeftShort className="app-color" /></h1>
			</NavLink>	
		</div>
      <div className='card shadow mb-3' style={{ background: "#736CED" }}>
      <div className='card-body'>
         <div className='text-center text-white'>
            Prestadores
         </div>
       </div>  
   </div>
   <div className="text-end">
      <div >
         <h1>
            <BsIcons.BsPlusCircleFill onClick={handleShowNew} style={{ color: "#736CED" }}/>
         </h1>
      </div>
   </div>

   <div className="pt-2">
      <div className="input-group ">
         <input placeholder="Buscar" className="form-control form-control-sm" type="text" onChange={(e) => setFiltro(e.target.value)} />
         <span className="input-group-text" id="basic-addon2">
            <BsIcons.BsSearch/>
         </span>
      </div>
   </div><br/>
   {APIData.filter(datos => datos.nombre.toLocaleLowerCase().includes(filtro.toLocaleLowerCase()) || datos.apellido.toLocaleLowerCase().includes(filtro.toLocaleLowerCase()) || datos.telefono.toLocaleLowerCase().includes(filtro.toLocaleLowerCase())).map((data,index) => {
   return (
   <div key={index}>
   {Number(data.estado) === 1? 
   <div className='card shadow mb-3' key={data.id}>
      <div className='card-body'>
         <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
         }}>
            <div style={{flex: 1}} >
               <span className="account-user-avatar ">
               <img src={data.avatar_url ? apiConfig+'prestadores/image/'+data.avatar_url : generico} alt="cronogram"  className="rounded-circle img-fluid"/>
               </span>
            </div>
            <div style={{flex: 7}} className='text-start'>{data.nombre},{' '}{data.apellido}</div>
            <div style={{flex: 1}}>            
            {Number(data.estado) === 1?
            <div   onClick={() =>
               onDelete(data.id)}>
               <h3>
                  <BsIcons.BsToggleOn />
               </h3>
            </div>
            :
            <div onClick={() =>
               onActive(data.id)}>
               <h3>
                  <BsIcons.BsToggleOff />
               </h3>
            </div>
            }   
            </div>               
            <div style={{flex: 1}}>
            <NavLink
                          exact
                          to={
                            "/profile/prestadores/update/" +
                            encodeURIComponent(
                              CryptoJS.AES.encrypt(
                                JSON.stringify(data),
                                "clave_secreta"
                              ).toString()
                            )
                          }
                        >
                          <div className="">
                            <h3>
                              <BsIcons.BsThreeDotsVertical />
                            </h3>
                          </div>
                        </NavLink>
            </div>
         </div>
      </div>
   </div>
   :
   <div className='card shadow mb-3' key={data.id}>
      <div className='card-body' >
        
         <div style={{
               display: 'flex',
               justifyContent: 'space-between',
               alignItems: 'center'
            }}>
               <div style={{flex: 1}}>
                  <span className="account-user-avatar ">
                  <img src={apiConfig+'prestadores/image/'+data.avatar_url} alt="cronogram"  className="rounded-circle img-fluid" style={{filter: 'grayscale(100%)'}}/>
                  </span>
               </div>
               <div style={{flex: 7}} className='text-start'>{data.nombre},{' '}{data.apellido}</div>
               <div style={{flex: 1}}>           
               {Number(data.estado) === 1?
               <div  onClick={() =>
                  onDelete(data.id)}>
                  <h3>
                     <BsIcons.BsToggleOn />
                  </h3>
               </div>
               :
               <div   onClick={() =>
                  onActive(data.id)}>
                  <h3>
                     <BsIcons.BsToggleOff />
                  </h3>
               </div>
               }   
               </div>               
               <div style={{flex: 1}}>
                  <div  data-bs-toggle="tooltip" data-bs-placement="top" title="Eliminar" onClick={() =>
                     onErase(data.id)}>
                     <h3>
                        <BsIcons.BsTrash />
                     </h3>
                  </div>
               </div>
            </div>
        
      </div>
   </div>
   }
   </div>
   )
   })}  
</div>
<Modal show={show} size="lg" onHide={handleClose}>
   <Modal.Header closeButton><h1>Agregar</h1></Modal.Header>
   <Modal.Body>
      <div className="container  mb-3">
         <div className="col text-center">
            {image1  || !image1 === 'null' || !image1 === '' ? (
            <>                     
            <img src={preview1} alt="" className="rounded-circle account-user " onClick={() => {                                  
            setPreview1(null);
            setImage1(null)}}/>
            </>
            ):(
            <>
            <input type="file" name="image1" id="image1" accept="image1/*" capture="camera" 
               onChange={(event) => { 
            const file = event.target.files[0];  ; 
            const reader = new FileReader();
            reader.onloadend = () => {
            setImage1(reader.result);
            };
            reader.readAsDataURL(file);
            setShow1(true)
            }} 
            ref={inputRef1} style={{ display: "none" }}/>
            <h1>
               <BsIcons.BsPlusCircleFill onClick={triggerFileSelectPopup1}/>
            </h1>
            </>
            )}
            <Modal show={show1} size="lg" onHide={handleClose1}>
               <Modal.Header closeButton>Imagen</Modal.Header>
               <Modal.Body>
                  <div className='containers'>
                     <div className='container-cropper'>
                        <div className='cropper'>
                           <Cropper image={image1} crop={crop} zoom={zoom} aspect={2/2} cropShape="round" showGrid={false} onCropChange={setCrop} onZoomChange={setZoom} onCropComplete={onCropComplete} />
                        </div>
                        <div className='slider'>
                           <Slider min={1} max={3} step={0.1} value={zoom} onChange={(e, zoom) =>
                           setZoom(zoom)}/>
                        </div>
                     </div>
                     <div className='container-buttons'>
                        <div className="col-1"></div>
                        <button className='col-3 btn app-button btn-lg' onClick={onDownload}>
                           <BsIcons.BsCheckLg />
                        </button>
                     </div>
                  </div>
               </Modal.Body>
            </Modal>
         </div>
        
         <br/><br/>
         <div className="center">
  <div className="col-12 pt-1">
    <Form onSubmit={registerUser}>
      <Row>
        <Col md={6} sm={12} className="mb-2">
          <FloatingLabel controlId="nombre" label="Nombre">
            <Form.Control
              name="nombre"
              type="text"
              className="form-control"
              onChange={(e) => setNombre(e.target.value)}
              pattern="^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$"
              maxLength={20}
              required
            />
          </FloatingLabel>
        </Col>
        <Col md={6} sm={12} className="mb-2">
          <FloatingLabel controlId="apellido" label="Apellido">
            <Form.Control
              name="apellido"
              type="text"
              className="form-control"
              onChange={(e) => setApellido(e.target.value)}
              pattern="^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$"
              maxLength={20}
              required
            />
          </FloatingLabel>
        </Col>
      </Row>
      <Row>
        <Col md={6} sm={12} className="mb-2">
          <FloatingLabel controlId="telefono" label="Teléfono">
            <Form.Control
              name="telefono"
              type="text"
              className="form-control"
              onChange={(e) => setTelefono(e.target.value)}
              minLength={10}
              maxLength={10}
              pattern="[0-9]+$"
              required
            />
          </FloatingLabel>
        </Col>
        <Col md={6} sm={12} className="mb-2">
          <FloatingLabel controlId="email" label="Correo electrónico">
            <Form.Control
              name="email"
              type="email"
              className="form-control"
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </FloatingLabel>
        </Col>
      </Row>
      <Row>
        <Col md={6} sm={12} className="mb-2">
          <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',                        
                     }}>
                    <FloatingLabel
                      controlId="floatingTotal1"
                      label="Contraseña"
                      className="col-10"

                    >
                      <Form.Control
                        name="password"
                        type={passwordType}
                        className="form-control"
                        onChange={(e) => setPassword(e.target.value)}
                        minLength={6}
                        maxLength={6}                        
                        required
                      />
                    </FloatingLabel>
                    <div onClick={togglePassword}>
                      {passwordType === "password" ? (
                        <small>Mostrar</small>
                      ) : (
                        <small>Ocultar</small>
                      )}
                    </div>
                    </div>
        </Col>
        <Col md={6} sm={12} className="mb-2">
          <FloatingLabel controlId="local" label="Local">
            <Form.Select
              className="form-select"
              name="id_business_office"
              onChange={(e) => setLocal(e.target.value)}
              required
            >
              <option selected></option>
              {locales &&
                locales.map((data, index) => (
                  <option value={data.id} key={index}>
                    {data.name}
                  </option>
                ))}
            </Form.Select>
          </FloatingLabel>
        </Col>
      </Row>
      <br />
      {message && (
        <div className="col-12 alert alert-primary" onClick={() => setMessage(null)}>
          {message}
        </div>
      )}
      <div className="d-grid mb-4">
        <Button type="submit" className="btn app-button shadow">
          Continuar
        </Button>
      </div>
    </Form>
  </div>
</div>
      </div>
   </Modal.Body>
</Modal>
<Modal show={show2} size="lg" onHide={handleClose2}>
   <Modal.Header closeButton><h1>Editar </h1></Modal.Header>
   <Modal.Body>
      <div className="container  mb-3">
         <div className="col text-center">
            <div className="avatar position-relative" >
               <img src={preview1} alt="" className="rounded-circle account-user "/>
               <input type="file" name="image1" id="image1" accept="image1/*" capture="camera" 
                  onChange={(event) => { 
               const file = event.target.files[0];  ; 
               const reader = new FileReader();
               reader.onloadend = () => {
               setImage1(reader.result);
               };
               reader.readAsDataURL(file);
               setShow3(true)
               }} 
               ref={inputRef1} style={{ display: "none" }}/><span className="position-absolute translate-middle badgesA rounded-pill " onClick={triggerFileSelectPopup1}><img src={cam} alt="cam"/></span> 
            </div>
            
            <Modal show={show3} size="lg" onHide={handleClose3}>
               <Modal.Header closeButton>Imagen </Modal.Header>
               <Modal.Body>
                  <div className='containers'>
                     <div className='container-cropper'>
                        <div className='cropper'>
                           <Cropper image={image1} crop={crop} zoom={zoom} aspect={2/2} cropShape="round" showGrid={false} onCropChange={setCrop} onZoomChange={setZoom} onCropComplete={onCropComplete} />
                        </div>
                        <div className='slider'>
                           <Slider min={1} max={3} step={0.1} value={zoom} onChange={(e, zoom) =>
                           setZoom(zoom)}/>
                        </div>
                     </div>
                     <div className='container-buttons'>
                        <div className="col-1"></div>
                        <button className='col-3 btn app-button btn-lg' onClick={onDownload1}>
                           <BsIcons.BsCheckLg />
                        </button>
                     </div>
                  </div>
               </Modal.Body>
            </Modal>
            
         </div>
         
         <div className="center">
            <div className=" col-12 pt-1">              
               <form onSubmit={updateUser}>
                  <div className="row">
                     <div className="col-md-6 col-sm-12 mb-2">
                        <small>Nombre</small>
                        <input type="hidden" name="id" value={datoSeleccionado.id}/>
                        <input name="nombre"  type="text" className="form-control" value={datoSeleccionado && datoSeleccionado.nombre}  onChange={handleChange} pattern="^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$"  maxLength={20} />                                
                     </div>
                     <div className="col-md-6 col-sm-12 mb-2">
                        <small>Apellido</small>                                
                        <input name="apellido"  type="text"  className="form-control" value={datoSeleccionado && datoSeleccionado.apellido} onChange={handleChange} pattern="^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$"   maxLength={20} />                                
                     </div>
                  
                  <div className="col-md-6 col-sm-12 mb-2">
                     <small>Teléfono</small>                                
                     <input name="telefono"  type="text"   className="form-control" value={datoSeleccionado && datoSeleccionado.telefono} onChange={handleChange} minLength={10} maxLength={10} pattern="[0-9]+$" />
                  </div>
                  <div className=" col-md-6 col-sm-12 mb-2">
                     <small>Correo electrónico</small>                                
                     <input name="email"  type="email"  className="form-control" value={datoSeleccionado && datoSeleccionado.email} onChange={handleChange}/>                             
                  </div>
				  <div className=" col-md-6 col-sm-12 mb-2">
                     <small>Local</small>
					 <select className="form-select"  name="id_business_office" value={datoSeleccionado && datoSeleccionado.id_business_office} onChange={handleChange}  required>
						<option selected></option>
						{locales && locales.map((data,index)=>(
							<option value={data.id} key={index}>{data.name}</option>
						))}
						

					</select>                              
                                               
                  </div>
				  </div>
                  <br/>
                  <div className="d-grid  mb-4">
                     <button type="submit" className="btn app-button shadow" >Continuar</button>
                  </div>
               </form>
            </div>
         </div>
      </div>
   </Modal.Body>
</Modal>
       
      </>
    )
}