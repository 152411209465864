import { useState, useEffect } from "react";
import * as BsIcons from "react-icons/bs";
import axios from "axios";
import { NavLink } from "react-router-dom";
import {apiConfig} from "../../../services/apiConfig";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import useAuth from "../../../auth/useAuth";
import CryptoJS from "crypto-js";

function Category() {
  const auth = useAuth();
  const user = JSON.parse(localStorage.getItem('user'));
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [data, setData] = useState([]);
  const [editar, setEditar] = useState([]);
  const [servicios, setServicios] = useState([]);
  const [registro, setRegistro] = useState({
    nombre: "",
    descripcion: "",
    estado: 1,
    id_business_office: user.id_business_office,
    id_business_unit: user.id_business_unit,
  });

  useEffect(() => {
    axios
      .get(apiConfig + "category/list/?id=" + Number(user.id_business_unit))
      .then((getData) => {
        setData(getData.data);
      });
    axios
      .get(
        apiConfig + "service/list/all.php?id=" + Number(user.id_business_unit)
      )
      .then((getData) => {
        setServicios(getData.data);
      });
  }, [user.id_business_unit]);

  function getData() {
    axios
      .get(apiConfig + "category/list/?id=" + Number(user.id_business_unit))
      .then((getData) => {
        setData(getData.data);
      });
  }

  function handleSubmit(e) {
    console.log(registro)
    e.preventDefault();
    axios
      .post(apiConfig + `category/add/index.php`, registro)
      .then((res) => {
        let { data } = res;
        if (Number(data.success) === 1) {
          getData();
          setShow(false);
        }
      })
      .catch(function (res) {});
  }
  function handleSubmit1(e) {
    e.preventDefault();
    axios
      .post(apiConfig + `category/put/editar.php`, editar)
      .then((res) => {
        let { data } = res;
        if (Number(data.success) === 1) {
          getData();
          setShow1(false);
        }
      })
      .catch(function (res) {
        console.log(res);
      });
  }

  const handleChange = (e) => {
    setRegistro({ ...registro, [e.target.name]: e.target.value });
  };

  const handleChange1 = (e) => {
    setEditar({ ...editar, [e.target.name]: e.target.value });
  };

  function onErase(id) {
    axios.get(apiConfig + `category/delete/delete.php?id=${id}`).then(() => {
      getData();
    });
  }

  function onDelete(id) {
    axios.get(apiConfig + `category/delete/index.php?id=${id}`).then(() => {
      getData();
    });
  }

  function onActive(id) {
    axios.get(apiConfig + `category/delete/active.php?id=${id}`).then(() => {
      getData();
    });
  }

  return (
    <div className="container">
      <div className="text-start">
        <NavLink exact to={"/config/"}>
          <h1 className="display-1">
            <BsIcons.BsArrowLeftShort className="app-color" />
          </h1>
        </NavLink>
      </div>
      <div className="card shadow mb-3" style={{ background: "#736CED" }}>
        <div className="card-body">
          <div className="text-center text-white">
            Adminitración de Categorías
          </div>
        </div>
      </div>
      <div className="text-center " style={{ color: "#736CED" }}>
        <small>
          Nota: Una vez creada la categoría ingresa a esta para poder agregar el
          servicio.
        </small>
      </div>
      <div className="text-end  ">
        <h1>
          <BsIcons.BsPlusCircleFill
            onClick={handleShow}
            style={{ color: "#736CED" }}
          />
        </h1>
      </div>
      <Modal show={show} size="lg" onHide={handleClose}>
        <Modal.Header closeButton>
        <h1>Agregar Categoría</h1>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <FloatingLabel
                controlId="floatingCodigo"
                label="Nombre *"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="nombre"
                  placeholder="Nombre"
                  onChange={handleChange}
                  required
                />
              </FloatingLabel>
            </Col>
            <Col md={6}>
              <FloatingLabel
                controlId="floatingDescripcion"
                label="Descripcion *"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="descripcion"
                  placeholder="Descripción"
                  onChange={handleChange}
                  required
                />
              </FloatingLabel>
            </Col>
          </Row>
          <div className="d-grid gap-2 pt-5">
            <button
              onClick={handleSubmit}
              className="btn app-button  text-white shadow"
            >
              Guardar
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {data &&
        data.map((datos, index) => (
          <>
            {Number(datos.estado) === 1 ? (
              <>
                <div className="card shadow mb-3" key={index}>
                  <div className="card-body">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ flex: 1 }}>
                        <BsIcons.BsFillRecordFill
                          style={{ color: "#30ff24" }}
                        />
                      </div>
                      <div className="text-start active" style={{ flex: 3, }}>
                        <NavLink
                          
                          exact
                          to={
                            "/config/servicios/" +
                            encodeURIComponent(
                              CryptoJS.AES.encrypt(
                                JSON.stringify(datos),
                                "clave_secreta"
                              ).toString()
                            )
                          }
                        >
                          <span style={{ color: "#736CED " }}>{datos.nombre}</span>
                        </NavLink>
                      </div>
                      <div className="text-start" style={{ flex: 2 }}>
                        {servicios ? (
                          <>
                            {" "}
                            {
                              servicios.filter(
                                (d) =>
                                  Number(d.id_categoria) === Number(datos.id)
                              ).length
                            }{" "}
                          </>
                        ) : (
                          "0"
                        )}{" "}
                        Servicios
                      </div>

                      {Number(datos.estado) === 1 ? (
                        <div
                          style={{ flex: 1 }}
                          onClick={() => onDelete(datos.id)}
                        >
                          <h3>
                            <BsIcons.BsToggleOn />
                          </h3>
                        </div>
                      ) : (
                        <div
                          style={{ flex: 1 }}
                          onClick={() => onActive(datos.id)}
                        >
                          <h3>
                            <BsIcons.BsToggleOff />
                          </h3>
                        </div>
                      )}
                      <div style={{ flex: 1 }}>
                        <div
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Edit"
                          onClick={() => setEditar(datos)}
                        >
                          <div className="">
                            <h3>
                              <BsIcons.BsThreeDotsVertical
                                onClick={handleShow1}
                              />
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Modal show={show1} size="lg" onHide={handleClose1}>
                  <Modal.Header closeButton>
                    <h1>Editar Categoría</h1>
                  </Modal.Header>
                  <Modal.Body>
                    <Row>
                      <Col md={6}>
                        <FloatingLabel
                          controlId="floatingCodigo"
                          label="Nombre *"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            name="nombre"
                            value={editar && editar.nombre}
                            placeholder="Nombre"
                            onChange={handleChange1}
                          />
                        </FloatingLabel>
                      </Col>
                      <Col md={6}>
                        <FloatingLabel
                          controlId="floatingDescripcion"
                          label="Descripcion *"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            name="descripcion"
                            value={editar && editar.descripcion}
                            placeholder="Descripción"
                            onChange={handleChange1}
                          />
                        </FloatingLabel>
                      </Col>
                    </Row>
                    <div className="d-grid gap-2 pt-5">
                      <button
                        onClick={handleSubmit1}
                        className="btn app-button  text-white shadow"
                      >
                        Guardar
                      </button>
                    </div>
                  </Modal.Body>
                </Modal>
              </>
            ) : (
              <>
                <div className="card shadow mb-3" key={index}>
                  <div className="card-body">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ flex: 1 }}>
                        <BsIcons.BsFillRecordFill
                          style={{ color: "#f80f00" }}
                        />
                      </div>

                      <div className="text-start" style={{ flex: 3 }}>
                        <NavLink
                          exact
                          to={
                            "/config/servicios/" +
                            encodeURIComponent(
                              CryptoJS.AES.encrypt(
                                JSON.stringify(datos),
                                "clave_secreta"
                              ).toString()
                            )
                          }
                        >
                          {datos.nombre}
                        </NavLink>
                      </div>
                      <div className="text-start" style={{ flex: 2 }}>
                        {servicios ? (
                          <>
                            {" "}
                            {
                              servicios.filter(
                                (d) =>
                                  Number(d.id_categoria) === Number(datos.id)
                              ).length
                            }{" "}
                          </>
                        ) : (
                          "0"
                        )}{" "}
                        Servicios
                      </div>
                      {Number(datos.estado) === 1 ? (
                        <div
                          style={{ flex: 1 }}
                          onClick={() => onDelete(datos.id)}
                        >
                          <h3>
                            <BsIcons.BsToggleOn />
                          </h3>
                        </div>
                      ) : (
                        <div
                          style={{ flex: 1 }}
                          onClick={() => onActive(datos.id)}
                        >
                          <h3>
                            <BsIcons.BsToggleOff />
                          </h3>
                        </div>
                      )}
                      <div style={{ flex: 1 }}>
                        <div
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Eliminar"
                          onClick={() => onErase(datos.id)}
                        >
                          <h3>
                            <BsIcons.BsTrash />
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        ))}
    </div>
  );
}

export default Category;
