import React, {useContext, useState} from 'react';
import { NavLink } from "react-router-dom";
import {AuthContext} from '../AuthProvider';
import logo from '../../assets/image/logo.png';
import './styles/Login.css';
import PWAInstallationButton from '../../components/PWAInstallationButton/PWAInstallationButton';


function Login() {       
    const [passwordType, setPasswordType] = useState("password")    
    const {loginUser,isLoggedIn} = useContext(AuthContext)    
    const initialState = {
        userInfo:{
            email:'',
            password:'',
        },
        errorMsg:'',
        successMsg:'',
    }
    const [state,setState] = useState(initialState);
    
    const togglePassword =()=>{
        if(passwordType==="password")
        {
         setPasswordType("text")
         return;
        }
        setPasswordType("password")
      };

    
    const onChangeValue = (e) => {        
        setState({
            ...state,
            userInfo:{
                ...state.userInfo,
                [e.target.name]:e.target.value
            }
        });

    }
    const submitForm = async (e) => {
        e.preventDefault(); 
        const data = await loginUser(state.userInfo);
         if(data.success && data.token){
            setState({
                ...initialState,
            });
          
            localStorage.setItem('loginToken', data.token); 
           
            await isLoggedIn();
        }
        else{
            alert(data.message)
        }           
        e.target.reset();


    }


    return (
            <div className="App-header-up">
                <div className="container ">
                    <div className="col-md-6 col-12  pt-5 mx-auto">
                        <div className='mb-3 text-center'>
                            <img src={logo} className='img-fluid App-logo'  alt='Cronogram' /><br/>
                            <h1>Cronogram</h1>
                        </div>
                        <form onSubmit={submitForm}>
                            <small>Correo electrónico / Teléfono</small>
                            <div className="input-group mb-3 ">
                            <input name="email"  type="text" className="form-control"  onChange={onChangeValue}   required/>                                    
                            </div>
                            <small>Contraseña</small>
                            <div className="input-group mb-3">
                            <input name="password"  type={passwordType} className="form-control"  onChange={onChangeValue}  required/>
                            <span className="input-group-text app-click" onClick={togglePassword}>                                      
                            { passwordType==="password"? 
                            <small>Mostrar</small>
                            :
                            <small>Ocultar</small>
                            }
                            </span>
                            </div>
                            <div className="d-grid  mb-4">
                            <button type="submit" className="btn app-button text-white shadow">Iniciar sesión</button>
                            </div>
                            <div className="text-space mb-5">
                            <NavLink exact to="/recuperar" >
                                <small>Olvidó la contraseña ?</small>
                            </NavLink>
                            <PWAInstallationButton/>
                            </div>
                            <div className="text-center">
                                <br/>
                              { // <NavLink exact to="/register" >
                                 //   <h3>Regístrate ahora !</h3>
                              //  </NavLink>
                            }
                            </div>
                        </form>
                    </div>
                </div>
                <div className="d-none d-sm-none d-md-block col-md-6  "></div>
            </div>
         );
}

export default Login;
